import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faStackOverflow, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>I'm a Full Stack web developer working from home</h3>
                            <div className="separator" />
                            <p>I was 14 when i wrote my first line of code.Now, I'm professional Full stack Developer having 4 years of Working Experience. As like other human being I'm social kind of person and I love to work within team, so here with me there are team of 10 people to give best solution with rapidity to clients. We have expertise in Web and Mobile Application development. We have delivered many software solutions to domestic as well as International Clients.
                            100% success rate in all projects and on-time service to all Clients are key feature of our team.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon style={{ fontSize : '30px'}} icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/M-ismail-ramzan')}/>
                                <FontAwesomeIcon style={{ fontSize : '30px'}} icon={faStackOverflow} className="social_icon" onClick={() => window.open('https://stackoverflow.com/users/11870533/ismail-ramzan?tab=profile')} />
                                
                                <FontAwesomeIcon style={{ fontSize : '30px'}} icon={faLinkedin} className="social_icon" onClick={() => window.open('www.linkedin.com/in/ismail-ramzan')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Web Design + Development" value={80} delay={1100} />
                                <Progress name="Mobile App" value={90} delay={1100} />
                                <Progress name="React.js + React Native" value={90} delay={1100} />
                                <Progress name="Node.js + Express" value={80} delay={1100} />
                                <Progress name="Automated Busniess" value={100} delay={1100} />
                                <Progress name="Python & Django" value={80} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero