import React from 'react'
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Glitch from 'components/glitch'
import Typewriter from 'typewriter-effect'
import ThemeContext from '../../context'
import animation from './newanimation.json';
import Lottie from 'react-lottie';
import ismail from './image2.jpg'
import styles from './mystyle.css'
import Particles from 'react-particles-js';

class Hero extends React.Component {

  static contextType = ThemeContext

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


    return (
      <div>
        <Particles className='hello'
          params={{
            particles: {
              number: { value: 80, density: { enable: false } },
              size: { value: 3, random: true },
              move: { direction: "bottom", out_mode: "out" },
              line_linked: { enable: false }
            },
            interactivity: {
              events: { onclick: { enable: true, mode: "remove" } },
              modes: { remove: { particles_nb: 3 } }
            }
          }} />
        <section id={`${this.props.id}`} className="hero" style={{ height: this.context.height }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 content">
                <Particles className='hello'
                  params={{
                    particles: {
                      number: { value: 80, density: { enable: false } },
                      size: { value: 1, random: true },
                      move: { direction: "bottom", out_mode: "out" },
                      line_linked: { enable: false }
                    },
                    interactivity: {
                      events: { onclick: { enable: true, mode: "remove" } },
                      modes: { remove: { particles_nb: 3 } }
                    }
                  }} />
                <div className="content-text">
                  <div className="line-text">
                    <h4>Hey, I'm</h4>
                  </div>
                  <h1 className='myname'>Ismail Ramzan</h1>
                  <Typewriter
                    options={{
                      strings: ['Full Stack Developer', 'Web App Developer', 'Mobile App Developer'],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 img">
                <Particles className='hello1'
                  params={{
                    particles: {
                      number: { value: 80, density: { enable: false } },
                      size: { value: 1, random: true },
                      move: { direction: "bottom", out_mode: "out" },
                      line_linked: { enable: false }
                    },
                    interactivity: {
                      events: { onclick: { enable: true, mode: "remove" } },
                      modes: { remove: { particles_nb: 3 } }
                    }
                  }} />
                <img
                  src={ismail}
                  alt="person"
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    maxWidth: '100%', // Ensure image is responsive
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

    )
  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <div>

          <img
            src={value.node.childImageSharp.fluid.src}
            className={`animated fadeIn move-${Math.floor(Math.random() * 10) % 2 === 0 ? 'up' : 'down'
              } float-image`}

            style={{
              left: `${index * 10}%`,
              bottom: `${Math.random() *
                (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
                +(index % 2 === 0 ? 70 : 10)}%`,
            }}
            alt="shape"
            key={index}
          />
        </div>
      )
    })

  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "person.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
)
